@font-face {
  font-family: 'PTMono';
  src: url('assets/fonts/PTMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'PTMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #19142b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#fancy-background { color: var(--background, #19142b); }


::selection {
  background: var(--primary, #9fef00);
  color: var(--background, #19142b);
}


svg.rsm-svg {
  background: #2a3240;
  border-radius: 6px;
}

:root {
  --toastify-color-dark: #19142b !important;
  --toastify-color-info: #04e4f4 !important;
  --toastify-color-success: #20e253 !important;
  --toastify-color-warning: #f6f000 !important;
  --toastify-color-error: #f80363 !important;
}
